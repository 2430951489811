import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Pagination, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { userEdit } from "../../features/editSlice";
import PageTitle from "../../hooks/PageTitle";
import AddUserModal from "../modal/AddUserModal";
import DeleteModal from "../modal/DeleteModal";
import EditUserModal from "../modal/EditUserModal";

const AddUser = () => {
  const { REACT_APP_EXPRESS_BACKEND_URL } = process.env;
  const [userList, setUserList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCounte] = useState(1);
  const [limit, setLimit] = useState(5);
  const dispatch = useDispatch();

  // Delete Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    axios
      .get(`/userroute?page=${page}&limit=${limit}`, { withCredentials: true })
      .then((res) => {
        setUserList(res.data.users);
        setPageCounte(res.data.pageCount);
      });
  }, [refetch, page, limit]);

  const handleDelet = (id, name) => {
    setName(name);
    setId(id);
    handleShow();
  };
  const deleteConfirm = () => {
    axios
      .delete(`/userroute/delete/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.msg) {
          toast.success(res.data.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRefetch(!refetch);
          handleClose();
        }
      });
  };

  const handleEdit = (user) => {
    setModalShowEdit(true);
    dispatch(userEdit(user));
  };

  const handlePageClick = (newPage) => {
    if (newPage >= 1 && newPage <= pageCount) {
      setPage(newPage);
    }
  }
  let startPage = Math.max(page - 1, 1);
  let endPage = Math.min(startPage + 2, pageCount);

  if (endPage - startPage < 2) {
    startPage = Math.max(endPage - 2, 1);
  }

  const handleLimit = (e) => {
    setLimit(parseInt(e.target.value));
  };

  return (
    <div className="login_form dashboard_page">
      <PageTitle title="Users" />

      <div className="d-flex justify-content-between dashboard_item">
        <h3>Users</h3>
        <Button
          onClick={() => setModalShow(true)}
          variant="primary"
          className="primary_bg"
        >
          Add User
        </Button>
      </div>
      <Table bordered size="lg">
        <thead>
          <tr>
            <th>Id</th>
            <th>Username</th>
            <th>Email</th>
            <th>Role</th>
            <th>Notify</th>
            <th>Company</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {userList &&
            userList?.map((user, index) => {
              return (
                <tr key={user._id}>
                  <td>{user._id}</td>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>{user.notifyApproval ? "true" : "false"}</td>
                  <td>{user?.company?.companyname}</td>
                  <td>
                    <span role="button" onClick={() => handleEdit(user)}>
                      Edit
                    </span>
                    &nbsp;&nbsp;
                    <span
                      role="button"
                      onClick={() => handleDelet(user._id, user.username)}
                      className="text-danger"
                    >
                      Delete
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>

      <AddUserModal
        refetch={refetch}
        setRefetch={setRefetch}
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
      <EditUserModal
        refetch={refetch}
        setRefetch={setRefetch}
        modalShow={modalShowEdit}
        setModalShow={setModalShowEdit}
      />
      <DeleteModal
        name={name}
        show={show}
        handleClose={handleClose}
        deleteConfirm={deleteConfirm}
      />
      <div className="pagination_product">
        <Form.Select
          disabled={userList?.length < 5}
          onChange={handleLimit}
          className="limit_select-lg"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="25">25</option>
          <option value="35">35</option>
          <option value="50">50</option>
        </Form.Select>
        <Pagination size="lg">
          <Pagination.First
            onClick={() => handlePageClick(page - 1)}
            disabled={page === 1}
          />
          {[...Array(endPage - startPage + 1)].map((_, index) => {
            const pageNumber = startPage + index;
            return (
              <Pagination.Item
                key={pageNumber}
                active={pageNumber === page}
                onClick={() => handlePageClick(pageNumber)}
              >
                {pageNumber}
              </Pagination.Item>
            );
          })}
          <Pagination.Last
            onClick={() => handlePageClick(page + 1)}
            disabled={page === pageCount}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default AddUser;
