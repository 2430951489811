import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assests/images/odg-logo.svg";
import { userLoggedOut } from "../../features/authSlice";
import CustomLink from "../../hooks/CustomLink";
import { UseAuth } from "../../hooks/UseAuth";
import { UseUserAdministrators } from "../../hooks/UseUserAdministrators";
import "./NavBar.css";
import userAvatar from '../../assests/images/user.png'

const NavBar = () => {
  const token = localStorage.getItem("accessToken");
  const { profile, user } = JSON.parse(token) || '';
  const profileURL = profile === '404' || profile === '' || profile === null ? userAvatar : profile;
  const { role, username } = user || '';
  const dispatch = useDispatch();
  const auth = UseAuth();
  const UserAdministrators = UseUserAdministrators();

  let content = null;
  let menuItem = null;
  let authItem = null;
  let authItemTwo = null;

  const handleLogOut = () => {
    dispatch(userLoggedOut());
    localStorage.removeItem("accessToken");
  };

  if (auth) {
    content = (
      <Button title={`${username} (${role})`} onClick={handleLogOut} variant="outline-secondary">
        <img className="profile_avatar" src={profileURL} alt="" />
        <i className="fa fa-sign-out" aria-hidden="true"></i>
      </Button>
    );
  }

  if (auth) {
    authItem = (
      <Nav.Link as={CustomLink} to="/">
        Home
      </Nav.Link>
    );
  }

  if (UserAdministrators) {
    menuItem = (
      <>
        {/* <Nav.Link as={CustomLink} to="/administration">Administration</Nav.Link> */}

        <NavDropdown title="Administration" id="basic-nav-dropdown">
          <NavDropdown.Item as={CustomLink} to="/administration">
            User
          </NavDropdown.Item>
          <NavDropdown.Item as={CustomLink} to="/administration/company-new">
            Company
          </NavDropdown.Item>
          <NavDropdown.Item as={CustomLink} to="/administration/apikey-new">
            API Key
          </NavDropdown.Item>
        </NavDropdown>
      </>
    );
  }
  return (
    <Navbar className="menubar_area" expand="lg">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img
              src={logo}
              width="161"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="d-flex justify-content-between menu_div">
            <div className="nav_items d-flex">

              {authItem}
              {menuItem}
              {authItemTwo}

            </div>
            {content}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
