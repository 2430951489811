import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Pagination, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { apiEdit } from "../../features/editSlice";
import PageTitle from "../../hooks/PageTitle";
import AddApiKeyModal from "../modal/AddApiKeyModal";
import DeleteModal from "../modal/DeleteModal";
import EditApiModal from "../modal/EditApiModal";

const ApiKey = () => {
  const { REACT_APP_EXPRESS_BACKEND_URL } = process.env;
  const [apis, setapiList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCounte] = useState(1);
  const [limit, setLimit] = useState(5);
  const [company, setCompanyname] = useState("404");
  const [companyList, setCompanyList] = useState([]);

  // Delete Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    axios
      .get(`/companyroute`, {
        withCredentials: true,
      })
      .then((res) => setCompanyList(res.data.company));
  }, []);
  useEffect(() => {
    if (company == '404') {
      axios.get(`/scanjobRoute/api?page=${page}&limit=${limit}`, { withCredentials: true })
        .then((res) => {
          setapiList(res.data.api);
          setPageCounte(res.data.pageCount);
        });
    }
    else {
      axios.post(`/scanjobRoute/apifilter?page=${page}&limit=${limit}`, { filter: company }, { withCredentials: true })
        .then((res) => {
          setapiList(res.data.api);
          setPageCounte(res.data.pageCount);
        });
    }


    // setCompanyname('404')
  }, [refetch, page, limit]);

  const handleDelet = (id, name) => {
    setName(name);
    setId(id);
    handleShow();
  };
  const deleteConfirm = () => {
    axios.delete(`/scanjobRoute/api/delete/${id}`, {
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.msg) {
          toast.success(res.data.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRefetch(!refetch);
          handleClose();
        }
      });
  };

  const handleEdit = (api) => {
    setModalShowEdit(true);
    dispatch(apiEdit(api));
  };

  const handlePageClick = (newPage) => {
    if (newPage >= 1 && newPage <= pageCount) {
      setPage(newPage);
    }
  }
  let startPage = Math.max(page - 1, 1);
  let endPage = Math.min(startPage + 2, pageCount);

  if (endPage - startPage < 2) {
    startPage = Math.max(endPage - 2, 1);
  }
  const handleLimit = (e) => {
    setLimit(parseInt(e.target.value));
  };

  const handleApiFilter = (id) => {
    setCompanyname(id);
    setPage(1)
    if (id == '404') {
      axios.get(`/scanjobRoute/api?page=${page}&limit=${limit}`, { withCredentials: true })
        .then((res) => {
          setapiList(res.data.api);
          setPageCounte(res.data.pageCount);
        });
    }
    else {
      axios.post(`/scanjobRoute/apifilter?page=${page}&limit=${limit}`, { filter: id }, { withCredentials: true })
        .then((res) => {
          setapiList(res.data.api);
          setPageCounte(res.data.pageCount);
        });
    }
  }

  return (
    <div className="login_form dashboard_page">
      <PageTitle title="Api Keys" />

      <div className="filter_company">
        <Form.Select
          className="mb-3"
          value={company}
          onChange={(e) => handleApiFilter(e.target.value)}
          aria-label="Default select example"
        >
          <option value="404">All Company</option>
          {companyList.map((company) => (
            <option key={company._id} value={company._id}>
              {company.companyname}
            </option>
          ))}
        </Form.Select>
      </div>

      <div className="d-flex justify-content-between dashboard_item">
        <h3>API Keys</h3>
        <Button
          onClick={() => setModalShow(true)}
          variant="primary"
          className="primary_bg"
        >
          Add API Key
        </Button>
      </div>
      <Table bordered size="lg">
        <thead>
          <tr>
            <th>Scanner ID</th>
            <th>Host Name</th>
            <th>API Key</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {apis && apis.map((api, index) => {
            return (
              <tr key={api._id}>
                <td>{api._id}</td>
                <td>{api.hostName}</td>
                <td>{api.api_key}</td>
                <td>
                  <span role="button" onClick={() => handleEdit(api)}>
                    Edit
                  </span>
                  &nbsp;&nbsp;
                  <span
                    role="button"
                    onClick={() =>
                      handleDelet(api._id, api.hostName)
                    }
                    className="text-danger"
                  >
                    Delete
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <AddApiKeyModal
        refetch={refetch}
        setRefetch={setRefetch}
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
      <EditApiModal
        refetch={refetch}
        setRefetch={setRefetch}
        modalShow={modalShowEdit}
        setModalShow={setModalShowEdit}
      />
      <DeleteModal
        name={name}
        show={show}
        handleClose={handleClose}
        deleteConfirm={deleteConfirm}
      />

      <div className="pagination_product">
        <Form.Select
          disabled={apis?.length < 5}
          onChange={handleLimit}
          className="limit_select-lg"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="25">25</option>
          <option value="35">35</option>
          <option value="50">50</option>
        </Form.Select>
        <Pagination size="lg">
          <Pagination.First
            onClick={() => handlePageClick(page - 1)}
            disabled={page === 1}
          />
          {[...Array(endPage - startPage + 1)].map((_, index) => {
            const pageNumber = startPage + index;
            return (
              <Pagination.Item
                key={pageNumber}
                active={pageNumber === page}
                onClick={() => handlePageClick(pageNumber)}
              >
                {pageNumber}
              </Pagination.Item>
            );
          })}
          <Pagination.Last
            onClick={() => handlePageClick(page + 1)}
            disabled={page === pageCount}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default ApiKey;
