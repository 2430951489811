import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Pagination, Spinner, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import PageTitle from "../../hooks/PageTitle";
import { UseUserAdministrators } from "../../hooks/UseUserAdministrators";
import DeleteModal from "../modal/DeleteModal";
import "./Home.css";

function prettySize(bytes, separator = " ", postFix = "") {
  if (bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.min(
      parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10),
      sizes.length - 1
    );
    return `~${(bytes / 1024 ** i).toFixed(i ? 1 : 0)}${separator}${
      sizes[i]
    }${postFix}`;
  }
  return "n/a";
}

const HomePage = () => {
  const userRole = UseUserAdministrators();
  const { REACT_APP_EXPRESS_BACKEND_URL } = process.env;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCounte] = useState(1);
  const [limit, setLimit] = useState(25);
  const [refetch, setRefetch] = useState(false);
  const [sort, setSort] = useState("");
  const [scanjobs, setScanjobs] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Delete Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    setIsLoading(true);
    if (!sort) {
      axios
        .get(`/scanjobRoute/allscanjob?page=${page}&limit=${limit}`, {
          withCredentials: true,
        })
        .then((res) => {
          console.log("resdata");
          console.log(res.data);
          setScanjobs(res.data.scanjob);
          setPageCounte(res.data.pageCount);
          setIsLoading(false);
        });
    } else {
      axios
        .get(
          `/scanjobRoute/allscanjob?page=${page}&limit=${limit}&sort=${sort}`,
          { withCredentials: true }
        )
        .then((res) => {
          setScanjobs(res.data.scanjob);
          setPageCounte(res.data.pageCount);
          setIsLoading(false);
        });
    }
  }, [page, limit, refetch, sort]);

  const handlePageClick = (newPage) => {
    if (newPage >= 1 && newPage <= pageCount) {
      setPage(newPage);
    }
  };
  let startPage = Math.max(page - 1, 1);
  let endPage = Math.min(startPage + 2, pageCount);

  if (endPage - startPage < 2) {
    startPage = Math.max(endPage - 2, 1);
  }

  const handleLimit = (e) => {
    setPage(1);
    setLimit(parseInt(e.target.value));
  };

  const sortingTable = (data) => {
    if (sort == data) {
      setSort("");
    } else {
      setSort(data);
    }
  };

  const handleDelet = (id, name) => {
    setName(name);
    setId(id);
    handleShow();
  };

  const deleteConfirm = () => {
    axios
      .delete(`/scanjobRoute/delete/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.msg) {
          toast.success(res.data.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRefetch(!refetch);
          handleClose();
        }
      });
  };
  return (
    <div>
      <PageTitle title="Home" />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <br />
            <br />
            <div className="d-flex justify-content-between dashboard_item">
              <h3>Render Jobs Overview</h3>
            </div>
            <Table className="scanJob_table" bordered size="lg">
              <thead>
                <tr>
                  <th>Id</th>
                  <th
                    className={sort === "comment" ? "comment" : ""}
                    role="button"
                    onClick={() => sortingTable("comment")}
                  >
                    Comment
                  </th>
                  <th
                    className={
                      sort === "jobUploadStatus" ? "jobUploadStatus" : ""
                    }
                    role="button"
                    onClick={() => sortingTable("jobPostStatus")}
                  >
                    JobPostStatus
                  </th>
                  <th
                    className={
                      sort === "jobUploadStatus" ? "jobUploadStatus" : ""
                    }
                    role="button"
                    onClick={() => sortingTable("deliveryMethod")}
                  >
                    DeliveryMethod
                  </th>
                  <th
                    className={
                      sort === "jobUploadStatus" ? "jobUploadStatus" : ""
                    }
                    role="button"
                    onClick={() => sortingTable("notifictionEmail")}
                  >
                    Notification Email
                  </th>
                  <th
                    className={
                      sort === "jobUploadStatus" ? "jobUploadStatus" : ""
                    }
                    role="button"
                    onClick={() => sortingTable("userFileUploadLink")}
                  >
                    UserFileUploadLink
                  </th>
                  <th
                    className={
                      sort === "jobUploadStatus" ? "jobUploadStatus" : ""
                    }
                    role="button"
                    onClick={() => sortingTable("ocFileLink")}
                  >
                    OCFileLink
                  </th>
                  <th
                    className={sort === "createdAt" ? "createdAt" : ""}
                    role="button"
                    onClick={() => sortingTable("createdAt")}
                  >
                    CreatedAt
                  </th>
                  <th
                    className={sort === "updatedAt" ? "updatedAt" : ""}
                    role="button"
                    onClick={() => sortingTable("updatedAt")}
                  >
                    UpdatedAt
                  </th>
                  {userRole ? <th>Actions</th> : ""}
                </tr>
              </thead>
              <tbody>
                {scanjobs &&
                  scanjobs.map((job) => {
                    return (
                      <tr key={job._id}>
                        <td>{job._id}</td>
                        <td title={job.comment}>
                          {job.comment &&
                            (job.comment.length > 25
                              ? job.comment.slice(0, 25) + "..."
                              : job.comment.slice(0, 25))}
                        </td>
                        <td title={job.jobPostStatus}>
                          {job.jobPostStatus &&
                            (job.jobPostStatus.length > 25
                              ? job.jobPostStatus.slice(0, 25) + "..."
                              : job.jobPostStatus.slice(0, 25))}
                        </td>
                        <td title={job.deliveryMethod}>
                          {"Email: " +
                            job.deliveryMethod.email +
                            "," +
                            "FileUpload: " +
                            job.deliveryMethod.fileUpload}
                        </td>
                        <td title={job.userId.username}>
                          {job.userId.username}
                        </td>
                        <td title={job.userFileUploadLink}>
                          {job.userFileUploadLink &&
                            (job.userFileUploadLink.length > 25
                              ? job.userFileUploadLink.slice(0, 25) + "..."
                              : job.userFileUploadLink.slice(0, 25))}
                        </td>
                        <td title={job.ocFileLink}>
                          {job.ocFileLink &&
                            (job.ocFileLink.length > 25
                              ? job.ocFileLink.slice(0, 25) + "..."
                              : job.ocFileLink.slice(0, 25))}
                        </td>
                        <td>
                          {new Date(job.createdAt).toLocaleString("en-US")}
                        </td>
                        <td>
                          {new Date(job.updatedAt).toLocaleString("en-US")}
                        </td>
                        {userRole ? (
                          <td>
                            <span
                              role="button"
                              onClick={() => handleDelet(job._id, job._id)}
                              className="text-danger"
                            >
                              Delete
                            </span>
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    );
                  })}
              </tbody>
              {isLoading && (
                <div className="scanJob_filter_loader">
                  <>
                    <Spinner size="lg" animation="border" />
                    &nbsp; <span>Loading Render Jobs...</span>
                  </>
                </div>
              )}
            </Table>

            <div className="pagination_product">
              <Form.Select
                disabled={scanjobs?.length < 5}
                onChange={handleLimit}
                className="limit_select-lg"
                value={limit}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </Form.Select>
              <Pagination size="lg">
                <Pagination.First
                  onClick={() => handlePageClick(page - 1)}
                  disabled={page === 1}
                />
                {[...Array(endPage - startPage + 1)].map((_, index) => {
                  const pageNumber = startPage + index;
                  return (
                    <Pagination.Item
                      key={pageNumber}
                      active={pageNumber === page}
                      onClick={() => handlePageClick(pageNumber)}
                    >
                      {pageNumber}
                    </Pagination.Item>
                  );
                })}
                <Pagination.Last
                  onClick={() => handlePageClick(page + 1)}
                  disabled={page === pageCount}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        name={name}
        show={show}
        handleClose={handleClose}
        deleteConfirm={deleteConfirm}
      />
    </div>
  );
};

export default HomePage;
